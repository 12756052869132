import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import styles from '../accountsettingsmodule/accountsettingscardscreen.module.css';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Card from '../../uikit/Card/Card';
import SvgProfiles from '../../icons/SvgProfiles';
import SvgBuildCareers from '../../icons/SvgBuildCareers';
import SvgSubsandBills from '../../icons/SvgSubsandBills';
import SvgManageUsers from '../../icons/SvgManageUsers';
import SvgIntegrations from '../../icons/SvgIntegrations';
import SvgWorkflow from '../../icons/SvgWorkflow';
import SvgTemplatesHub from '../../icons/SvgTemplatesHub';
import SvgNotificationPref from '../../icons/SvgNotificationPref';

type props = {
  mapvalue?: any;
};

const AccountSettingsCardScreen = ({ mapvalue }) => {
  const history = useHistory();
  const isTablet = useMediaQuery({ query: '(max-width: 1000px)' });
  const normal = useMediaQuery({
    query: '(min-width: 1000px) and (max-width: 1411px)',
  });

  const iconchangefunction = (value) => {
    if (value === 'SvgProfiles') {
      return <SvgProfiles width={30} height={30} />;
    } else if (value === 'SvgBuildCareers') {
      return <SvgBuildCareers width={30} height={30} />;
    } else if (value === 'SvgSubsandBills') {
      return <SvgSubsandBills width={30} height={30} />;
    } else if (value === 'SvgManageUsers') {
      return <SvgManageUsers width={30} height={30} />;
    } else if (value === 'SvgIntegrations') {
      return <SvgIntegrations width={30} height={30} />;
    } else if (value === 'SvgWorkflow') {
      return <SvgWorkflow width={30} height={30} />;
    } else if (value === 'SvgTemplatesHub') {
      return <SvgTemplatesHub width={30} height={30} />;
    } else {
      return <SvgNotificationPref width={30} height={30} />;
    }
  };

  const navigate = (path: string) => {
    window.history.pushState({}, '', path);
    const navigationEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navigationEvent);
  };

  const Navigate_page = (link: string, name: string) => {
    if (name === 'Profiles') {
      sessionStorage.setItem('MyAccountDropdown', '1');
    } else if (name === 'Build Careers Page') {
      sessionStorage.setItem('BrandDropdown', '1');
    } else if (name === 'Subscription & Billing') {
      sessionStorage.setItem('MyAccountDropdown', '1');
    } else if (name === 'Manage Users') {
      sessionStorage.setItem('MyAccountDropdown', '1');
    } else if (name === 'Integrations') {
      sessionStorage.setItem('CommunicationDropdown', '1');
    } else if (name === 'Hiring Workflow') {
      sessionStorage.setItem('JobsDropdown', '1');
    }
   // window.location.href = link;
    // history.push(link);
    navigate(link)
  };

  return (
    <>
      <Flex row className={styles.ribbon} marginBottom={5} between>
        <Flex marginTop={9} marginLeft={8}>
          <Text size={16} bold color="theme">
            Account Settings
          </Text>
        </Flex>
        <Flex>
          <div className={styles.triangle}></div>
        </Flex>
      </Flex>
      <Flex style={{ padding: 10, overflow: 'scroll' }}>
        <Flex className={styles.wrapCards}>
          {mapvalue.map((value, index) => (
            <>
              <Flex
                style={{
                  width: isTablet ? '100%' : normal ? '50%' : '33%',
                }}
                key={index}
              >
                <Card className={styles.accountcards}>
                  <Flex row>
                    <Flex className={styles.iconHolder}>
                      {iconchangefunction(value.Icon)}
                    </Flex>
                    <Flex marginLeft={15}>
                      <Text bold>{value.card_name}</Text>
                      <Flex className={styles.descriptionText}>
                        {value.discription}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex center middle className={styles.bottomlink}>
                    <Text
                      bold
                      color="theme"
                      style={{ cursor: 'pointer' }}
                      onClick={() => Navigate_page(value.link, value.card_name)}
                    >
                      {value.button}
                    </Text>
                  </Flex>
                </Card>
              </Flex>
            </>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default AccountSettingsCardScreen;
